
export default {
    emits: [
        'done',
        'update:visible'
    ],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 修改回显的数据
        data: Object,
        // 业务员
        salesman_list: Array,
        // 会员类型
        userTypes: Array
    },
    data() {
        return {
            // 表单数据
            form: Object.assign({}, this.data),
            // 表单验证规则
            rules: {
                password: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }],
                company_name: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }],
                contact_name: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }],
                contact_email: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }],
                contact_tel: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }],
                balance: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'number',
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (isNaN(Number(value))) {
                            callback(new Error());
                        } else {
                            callback();
                        }
                    }
                }],
                credit: [{
                    required: true,
                    message: this.$t('请填写有效整数'),
                    type: 'string',
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (isNaN(Number(value))) {
                            callback(new Error());
                        } else {
                            callback();
                        }
                    }
                }],
                frozen: [{
                    required: true,
                    message: this.$t('请填写有效整数'),
                    type: 'string',
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (isNaN(Number(value))) {
                            callback(new Error());
                        } else {
                            callback();
                        }
                    }
                }]
            },
            // 提交状态
            loading: false,
            // 是否允许修改用户余额
            allow_edit_balance: false
        };
    },
    watch: {
        data() {
            if (this.data) {
                this.form = Object.assign({}, this.data);
            } else {
                this.form = {
                    type_id: this.userTypes[0]?.value || "0",
                    type_name: this.userTypes[0]?.label || ""
                };
            }
            if (this.$refs.form) {
                this.$refs.form.clearValidate();
            }
        }
    },
    async mounted() {
        this.loadData();
    },
    methods: {
        /**
         * 加载基础数据
         */
        loadData() {
            this.$http.get(`/system_config/basic/allow_edit_balance`).then(res => {
                this.loading = false;

                if (res.data.code === 0) {
                    this.allow_edit_balance = res.data.data === '1';
                }
            }).catch(() => {});
        },
        /**
         * 保存数据
         */
        save() {
            this.$refs.form.validate().then(() => {
                this.loading = true;
                this.$http.post(`/admin/user/${this.form.id?'update':'create'}`, this.form).then(res => {
                    this.loading = false;
                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.form = {};
                        this.updateVisible(false);
                        this.$emit('done');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }).catch(() => {});
        },
        updateVisible(value) {
            this.$emit('update:visible', value);
        },
        onClose() {
            this.updateVisible(false);
        }
    }
}
